export const environment:any = {
    PASSWORD: 'adjkvknh29827',
    appVersion: '0.0.0',
    production: 'true',
    ILN8PATH:'assets/i18n',
    SERVERPORT:'9916',
    hosturl: 'https://smatoapps.com:9911/',
    notifcationkey:'BH_46P2gdiNMXH-a-Nl7JkPZ-ugy9Vrq96JgetdkxqQBDVSsXiYXbLD0cfgIcwXX-s8I0Z_lRl0aVnQyAdEdAqw',
    homeScreenLogo: './assets/logo.png',
    landingScreenLogo: 'false',
    landingScreenBanner: true,
    organizationName: 'Smartify Sol',
    organizationUrl: 'support@smartifysol.com',
    currentYear: new Date().getFullYear()
  };
  